.App {
  text-align: center;
  background-color: #fbfbfb;
   -webkit-text-size-adjust: none;
}
body {
  -webkit-text-size-adjust: none;
}
:root {
  --primary-color:#4c2d33;/* #331a1f; #A67C84; */
  --secondary-color: #f5f5f5;
  --secondary-complement-color: #D1D1D1;
  --tertiary-color: #7d9180; /* #6C95A6; */
  --tertiary-complement-color: #556357;
  --quarter-color: #4c626c;
  --quarter-complement-color: #5f7b87;
  --fifth-color: #61313b; /*#593A40;*/
  --fifth-complement-color: #593a40;
  --sixth-color: #b9b9b9;
  --seven-color: #596c76;
  --eight-color: #bfb3a8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.row {
  margin-top: 0 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}
