.footer-bottom{
    color: white;

    outline: thick solid var(--fifth-color);
}

.bg-light{
    background-color: var(--secondary-color) !important;
   
}

.mx-auto.mb-4.marginB {
    padding-bottom: 1rem;
}