.backToTopButton {
  z-index: 1200;
  height: 50px;
  width: 50px;
  position: fixed;
  opacity: 0;
  visibility: 0;
  display: hidden;
  bottom: 50px;
  right: 40px;
  margin: 0px;
  padding: 0px;
  background-color: #eaeae99b;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  border-width: 0;
}

.backToTopButton:hover {
  background-color: #d1d1d1c9;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  transition: 0.15s linear;
}

.backToTopButton:hover .hiArrowUp {
  height: 35px;
  width: 35px;
  transition: 0.05s linear;
}

.submitButton {
  background-color: var(--quarter-complement-color) !important;
  border: var(--quarter-complement-color);
  width: 175px;
  box-shadow: 0 0 0px var(--quarter-complement-color) !important ;
  font-family: "Noto Sans Mono", monospace;
  letter-spacing: +1.5px;
  font-weight: 500;
  transition: background-color 0.3s ease; /* Animation */

  &:hover {
    transition: 0.15s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: background-color 0.3s ease; /* Animation */
    letter-spacing: +1.5px;
    font-weight: 600;
    background-color: var(--seven-color) !important;
    color: white;
    border: var(--seven-color);
    box-shadow: 0 0 2px var(--seven-color) !important ;
  }
}

.btn-light:first-child:hover,
.btn-light:focus-visible,
.btn-light:hover {
  box-shadow: 0 8px 9px -4px rgba(0, 0, 0, 0.3), 0 4px 18px 0 rgba(0, 0, 0, 0.2) !important;
}
