.navbarBrand {
  justify-content: center;
  justify-self: center;
  width: 100vw;
  display: flex;
}

.headerLink-D-Wrapper {
  /* width: calc(inherit - 10%); */
  z-index: 100;
  user-select: none;
  position: absolute;
  padding: 20pt 0 0 0;
  max-width: 100%;

  > ul {
    margin: auto;
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0 20pt;
    gap: 16pt; /* Adjust the spacing between list items */
  }

  > li {
    display: inline-block;
  }
  & .headerLink-D {
    position: relative;
    display: inline-block;
    text-decoration: none; /* remove the default underline */
    color: grey; /* non-active color */
    transition: color 0.3s ease; /* smooth transition for hover effect */
    font-size: 12pt;
    font-weight: 500;
    word-spacing: -4px;
    font-family: "Noto Sans Mono", monospace;
        align-items: center;
    &:hover {
      color: white;
      font-weight: 600;
      /* text-shadow: 1px 2.5px 1px rgba(191, 191, 191, 0.424); */
    }
    &.select {
      font-weight: 600;
      color: white;
      align-items: center;
      &:hover {
        color: white;
        font-weight: 500;
        /* text-shadow: 1px 2.5px 1px rgba(191, 191, 191, 0.424); */
      }
    }
  }
}

.headerLink-D-Popover-Wrapper {
  z-index: 1200;
  width: 100%;
  @media screen and (min-width: 1400px) {
    width: 40vw;
  }

  position: fixed;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: hidden;
  top: 0;
  padding: 0 0pt 0 0;
  background-color: #eaeae9;
  box-shadow: 2px 2px 5px rgba(123, 123, 123, 0.5);
  user-select: none;

  @media (min-width: 2560px) {
    max-width: unset;
    width: fit-content;
  }

  > ul {
    margin: auto;
    width: fit-content;
    list-style: none;
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 0pt 0;
    margin: 0;
    padding: 20pt 20pt 20pt 20pt;
    gap: 16pt; /* Adjust the spacing between list items */
  }
  > li {
    display: inline-block;
  }
  & .headerLink-D {
    position: relative;
    display: inline-block;
    text-decoration: none; /* remove the default underline */
    color: rgba(112, 112, 112, 0.712); /* non-active color */
    transition: color 0.3s ease; /* smooth transition for hover effect */
    font-size: 12pt;
    font-weight: 500;
    word-spacing: -4px;
    font-family: "Noto Sans Mono", monospace;
    > .me-3 {
      opacity: 0.4;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
        align-items: center;
    &:hover {
      color: black;
      font-weight: 600;
      > .me-3 {
        opacity: 1 !important;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
    &.select {
      font-weight: 600;
            align-items: center;
      color: rgb(40, 40, 40);
      > .me-3 {
        opacity: 1;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &:hover {
        color: rgb(40, 40, 40);
        font-weight: 600;
      > .me-3 {
        opacity: 4;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      }
    }
  }
}

.headerLink {
  justify-content: center;
  display: flex;
  width: fit-content;
  height: 60px;
  & .imageDivWrapper {
    height: inherit;

    & img {
      height: inherit;
    }
    & #logo {
      opacity: 1;
      width: 36.4px;
      transition: filter 0.15s ease-in-out;
      &:hover {
        filter: hue-rotate(90deg);
        filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
      }
    }

    & #logoColor {
      opacity: 0;
      width: 0px;
    }
  }
}

@media (min-width: 1600px) {
  .headerLink-D-Wrapper {
    > ul {
      gap: 18pt; /* Adjust the spacing between list items */
    }

    & .headerLink-D {
      font-size: 15pt;
    }
  }
  .headerLink-D-Popover-Wrapper {
    > ul {
      gap: 18pt; /* Adjust the spacing between list items */
    }

    & .headerLink-D {
      font-size: 15pt;
    }
  }
}

li {
  align-self: center !important;
  display: flex;
  & a {
    display: flex !important;
    align-self: center;
  }
}
